<template>
  <div class="unit-search-comp">
    <div style="position: relative">
      <b-form-group>
        <b-input-group>
          <b-form-input
            v-b-toggle.collapse-1
            v-model="search_label"
            :placeholder="$t('Notification.SearchGroupsUnits')"
            :class="
              visible ? 'unit-icon-input collapsed-icons' : 'unit-icon-input'
            "
            :disabled="!isEditable || isViewableOnly"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-4"
            @focus="handleFocus"
            @click="mouseEntered(search_label)"
            @blur="handleBlur"
            @input="debounce(search_label)"
          ></b-form-input>
          <b-input-group-append
            v-if="search_label"
            class="input-prefix-img search-clear curserPointer"
          >
            <feather-icon icon="XIcon" @click="clearTxt" size="20" />
          </b-input-group-append>
        </b-input-group>

        <b-collapse
          id="collapse-4"
          v-model="visible"
          class="unit-icon-modal mb-1 unit-selection-responsive"
          :class="
            ['dashboard'].includes(this.componentKey) && isAllUnit
              ? 'Unit-dashboard-scroll dash-notification-scroll'
              : ''
          "
        >
          <div
            class="mt-1"
            v-if="
              ['dashboard', 'report'].includes(this.componentKey) &&
              !show &&
              isUnitsGroupsAvailable &&
              !disableUnit &&
              (!filter || !filter.value) &&
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.UNITS
              })
            "
          >
            <div>
              <h5>
                {{ $t("unit.EmptyUnitCreateMessage") }}
              </h5>
            </div>

            <div style="text-align: center">
              <b-button
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.ADD,
                    subject: constants.PERMISSIONS_MODEL.UNITS
                  }) && !isViewableOnly
                "
                variant="primary"
                :to="{
                  name: 'unit-creation',
                  query: { allocate_to: accountId }
                }"
              >
                <feather-icon icon="PlusIcon" size="12" class="add-icon-s" />
                <span class="d-none d-sm-inline">{{
                  $t("device.CreateUnit")
                }}</span>
              </b-button>
            </div>
          </div>
          <div
            v-else
            class="unit-icon-card"
            :class="isAllUnit ? 'group-hide' : ''"
          >
            <div class="m-2-br-alert-bottm" v-if="!disableUnit">
              <div class="adduser-btn-row">
                <b-form-checkbox
                  :disabled="isViewableOnly"
                  v-if="['dashboard', 'report'].includes(this.componentKey)"
                  v-model="isAllUnit"
                  @change="(e) => allUnit(e)"
                >
                  <h4 v-if="$route.name === 'dashboard'">
                    {{ $t("Notification.SearchInUnits") }}
                  </h4></b-form-checkbox
                >
                <h4 class="card-title" v-if="$route.name !== 'dashboard'">
                  {{ $t("Notification.SearchInUnits") }}
                  {{ search_label ? `“${search_label}”` : "" }}
                </h4>

                <div
                  class="d-flex justify-content-end"
                  v-if="!['dashboard', 'report'].includes(this.componentKey)"
                >
                  <b-button
                    v-b-tooltip.hover.v-primary
                    :title="$t('Notification.AddUnitsGroups')"
                    variant="primary"
                    class="btn-icon"
                    @click="collectUnits"
                  >
                    <feather-icon icon="PlusIcon" size="12" />
                    {{ $t("Notification.AddUnitsGroups") }}
                  </b-button>
                </div>
              </div>
            </div>

            <UnitGroupSelectionList
              v-if="!disableUnit"
              class="unit-selection"
              :getSelectedUnit="getSelectedUnit"
              :pagination="pagination"
              :unitData="unitData"
              :show="show"
              :tableColumns="unitTableColumns"
              :getSelectedUnSelectedUnits="getSelectedUnSelectedUnits"
              :componentKey="componentKey"
              :collectUnits="collectUnits"
              :toggleAllUnits="toggleAllUnits"
              :isViewableOnly="isViewableOnly"
              :isSearching="isSearching"
            ></UnitGroupSelectionList>
            <div class="m-2-br-alert-bottm mt-2" v-if="!this.isAllUnit">
              <div class="adduser-btn-row">
                <h4 class="card-title">
                  {{ $t("groups.Groups") }}
                  {{ search_label ? ` “${search_label}”` : " " }}
                </h4>
              </div>
            </div>

            <UnitGroupSelectionList
              v-if="!this.isAllUnit || disableUnit"
              class="group-selection"
              :isGroupDisable="isAllUnit && !disableUnit"
              :getSelectedUnit="getSelectedUnit"
              :tableColumns="groupTableColumns"
              :pagination="pagination"
              :unitData="groupData"
              :show="show"
              :alertId="alertId"
              :getSelectedUnSelectedUnits="getSelectedUnSelectedUnits"
              :componentKey="componentKey"
              :collectUnits="collectUnits"
              :isViewableOnly="isViewableOnly"
              :isSearching="isSearching"
            ></UnitGroupSelectionList>
          </div>
        </b-collapse>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import UnitGroupSelectionList from "@/views/Notification/UnitGroupSelectionList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UnitService from "@/libs/api/unit-service";
import NotificationService from "@/libs/api/notification-service";
import debounce from "lodash/debounce";
import {
  BCard,
  BCollapse,
  BButton,
  VBToggle,
  BFormInput,
  BCol,
  BInputGroup,
  VBTooltip,
  BFormGroup,
  BFormCheckbox
} from "bootstrap-vue";
import EventBus from "@/EventBus";

// Note: Vue automatically prefixes the directive name with 'v-'

export default {
  name: "iconView",
  components: {
    BCard,
    UnitGroupSelectionList,
    BCollapse,
    BButton,
    VBToggle,
    BFormInput,
    BCol,
    VBTooltip,
    BInputGroup,
    BFormGroup,
    BFormCheckbox
  },
  props: [
    "alertId",
    "reportId",
    "unitFun",
    "sensors",
    "reportType",
    "selectedUnitByUser",
    "isEditable",
    "selectedUnitsData",
    "notification",
    "isClear",
    "getUnitLookupCount",
    "totalLookUpCount",
    "removedSelectedItem",
    "componentKey",
    "accountId",
    "isAllSelectedUnit",
    "isAllUnitEnabled",
    "isViewableOnly",
    "disableUnit"
  ],

  data() {
    return {
      test: "",
      icon_path: "",
      search_label: "",

      selectedUnits: [],
      visible: ["dashboard", "report"].includes(this.componentKey)
        ? true
        : false,
      isUnitsGroupsAvailable: true,
      isCallApi: false,
      show: "",
      currentPage: 1,
      perPage: 5,
      key: "ALL",
      isSearching: false,
      unitData: {
        items: [],
        total: 0,
        key: ""
      },
      groupData: {
        items: [],
        total: 0,
        key: ""
      },
      timeoutId: "",
      selectedUnselectedUnit: [],
      unitStore: [],
      groupStore: [],
      unitLookupCount: 0,
      oldIcons: [],
      removedGroupUnitsTemp: [],
      selectedUnselectedItems: {
        selected: [],
        unSelected: []
      },
      icons: [],
      groupTableColumns: ["dashboard", "report"].includes(this.componentKey)
        ? [
            {
              key: "name",
              Class: "",
              tdClass: "unit-col1-width",
              thClass: "unit-col1-width"
            }
          ]
        : [
            {
              key: "name",
              Class: "",
              tdClass: "unit-col1-width",
              thClass: "unit-col1-width"
            },
            {
              key: "units",
              Class: "",
              tdClass: "unit-col2-width",
              thClass: "unit-col2-width"
            },
            {}
          ],
      unitTableColumns: ["dashboard", "report"].includes(this.componentKey)
        ? [
            {
              key: "name",
              Class: "",
              tdClass: "unit-col1-width",
              thClass: "unit-col1-width"
            }
          ]
        : [
            {
              key: "name",
              Class: "",
              tdClass: "unit-col1-width",
              thClass: "unit-col1-width"
            },
            {
              key: "labels",
              Class: "",
              tdClass: "group-col2-width st-170",
              thClass: "group-col2-width st-170"
            },
            { key: "status", tdClass: "status-width group-col2-width" }
          ],
      isAllUnit: false,
      filter: {},
      responseUnitData: { selected: [] },
      isInputActive: false
      // isAllUnitGroup: true
    };
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    if (this.selectedUnitsData && this.selectedUnitsData.length) {
      this.selectedUnselectedItems.selected = this.selectedUnitsData;
      this.unitFun(this.selectedUnselectedItems, true, this.isAllUnit);
    }
    if (["dashboard", "report"].includes(this.componentKey)) {
      this.perPage = 50;
      this.getGroupUnitLookUp({
        currentPage: this.currentPage,
        perPage: this.perPage,
        key: this.key
      });
    }
    if (["report"].includes(this.componentKey)) {
      if (this.isAllSelectedUnit || this.$route.name === "select-unit") {
        this.isAllUnit = true;
      } else {
        this.isAllUnit = false;
      }
    }
    if (["dashboard"].includes(this.componentKey)) {
      this.isAllUnit = true;
      setTimeout(() => {
        this.unitFun(this.selectedUnselectedItems, "isAllUnit", this.isAllUnit);
      }, 500);
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  watch: {
    search_label(oldVal, newVal) {
      this.debounce(newVal, 500);
    },
    removedSelectedItem(val) {
      this.selectedUnselectedItems.selected =
        this.selectedUnselectedItems.selected.filter((e) => e.id != val.id);
      const alreadyExists = this.selectedUnselectedItems.unSelected.find(
        (o) => o.id === val.id
      );
      if (alreadyExists && alreadyExists.id) {
        return;
      }
      this.selectedUnselectedItems.unSelected.push(val);
    },
    isClear() {
      if (this.$route.name === "notification-create") {
        this.selectedUnselectedUnit = [];
        this.unitStore = [];
        this.groupStore = [];
        this.removedGroupUnitsTemp = [];
        this.selectedUnselectedItems = {
          selected: [],
          unSelected: []
        };

        this.selectedUnits = [];
      }
    },
    accountId: function (newValue, oldValue) {
      if (newValue && oldValue && newValue !== oldValue) {
        if (["dashboard", "report"].includes(this.componentKey)) {
          this.getGroupUnitLookUp({
            currentPage: this.currentPage,
            perPage: this.perPage,
            key: this.key
          });
          this.visible = true;
        }
      }
    },
    reportType() {
      this.getGroupUnitLookUp({
        currentPage: this.currentPage,
        perPage: this.perPage,
        key: this.key
      });
      this.visible = true;
    },
    isAllSelectedUnit() {
      if (["report"].includes(this.componentKey)) {
        if (this.isAllSelectedUnit) {
          this.isAllUnit = true;
        } else {
          this.isAllUnit = false;
        }
      }
    },
    isAllUnit(val) {
      this.isAllUnitEnabled(val);
    }
  },
  computed: {},

  methods: {
    handleFocus() {
      // When the input gets focus
      if (!this.isInputActive) {
        this.isInputActive = true;
        this.getGroupUnit(this.search_label);
      }
    },
    handleBlur() {
      // When the input loses focus
      this.isInputActive = false;
    },

    debounce(newVal, delay) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getGroupUnit(newVal);
      }, delay);
    },

    toggleAllUnits() {
      if (["dashboard", "report"].includes(this.componentKey)) {
        this.isAllUnit = false;
        this.unitFun(this.selectedUnselectedItems, "isAllUnit", this.isAllUnit);
      }
    },
    clearTxt() {
      this.search_label = "";
    },
    mouseEntered() {
      this.visible = true;
    },
    async allUnit(e) {
      if (e) {
        this.unitData.items.forEach((element) => {
          element.selected = true;
        });
        this.groupData.items.forEach((element) => {
          element.selected = false;
        });
        this.selectedUnselectedItems.selected = this.responseUnitData.selected;
        this.selectedUnselectedItems.unSelected = [];
        this.unitFun(this.selectedUnselectedItems, "isAllUnit", this.isAllUnit);
      } else {
        this.unitData.items.forEach((element) => {
          element.selected = false;
        });
        this.selectedUnselectedItems.unSelected =
          this.selectedUnselectedItems.selected;
        // this.selectedUnselectedItems.selected = [];
        this.selectedUnselectedItems.unSelected = [];
        this.selectedUnselectedItems.selected = [];
        await this.unitFun(
          this.selectedUnselectedItems,
          "isAllUnit",
          this.isAllUnit
        );
      }
    },

    getSelectedUnit(e, key) {
      if (key === "unit") {
        this.unitStore = e;
      }
      if (key === "group") {
        this.groupStore = e;
      }
    },
    handleOutsideClick(event) {
      const clickedElement = event.target;
      if (!this.$el.contains(clickedElement)) {
        this.visible = ["dashboard", "report"].includes(this.componentKey)
          ? true
          : false;
      }
    },
    collectUnits() {
      this.selectedUnits = [...this.unitStore, ...this.groupStore];

      this.unitFun(this.selectedUnselectedItems, false, this.isAllUnit);
      const count = this.totalLookUpCount + this.unitLookupCount;
      this.getUnitLookupCount(count);
      this.unitLookupCount = 0;

      this.search_label = "";
      setTimeout(() => {
        if (!["dashboard", "report"].includes(this.componentKey)) {
          this.visible = false;
        }
      }, 100);
    },
    getGroupUnit(e) {
      this.isSearching = true;
      if (["dashboard", "report"].includes(this.componentKey) && !e) {
        this.isSearching = false;
        return;
      }
      this.getGroupUnitLookUp({
        currentPage: this.currentPage,
        perPage: this.perPage,
        key: this.key
      })
        .then(() => {
          this.isSearching = false;
          this.visible = true;
        })
        .catch(() => {
          this.isSearching = false;
        });
    },
    getSelectedUnSelectedUnits(e, data, key) {
      if (e) {
        const alreadyExists = this.selectedUnselectedItems.selected.find(
          (o) => o.id === data.id
        );
        if (alreadyExists && alreadyExists.id) {
          return;
        }
        this.selectedUnselectedItems.selected.push(data);
        this.selectedUnselectedItems.unSelected =
          this.selectedUnselectedItems.unSelected.filter(
            (u) => u.id != data.id
          );
        this.unitLookupCount = this.unitLookupCount + 1;
      } else {
        const alreadyExists = this.selectedUnselectedItems.unSelected.find(
          (o) => o.id === data.id
        );
        if (alreadyExists && alreadyExists.id) {
          return;
        }
        this.selectedUnselectedItems.unSelected.push(data);
        this.selectedUnselectedItems.selected =
          this.selectedUnselectedItems.selected.filter((u) => u.id != data.id);
        this.unitLookupCount = this.unitLookupCount - 1;
      }
    },
    async getGroupUnitLookUp(filter) {
      try {
        // if (this.isCallApi) {
        //   return;
        // }
        if (
          this.selectedUnitsData &&
          this.selectedUnitsData.length &&
          this.$route.name === "repeaters"
        ) {
          this.selectedUnselectedItems.selected = this.selectedUnitsData;
          await this.unitFun(
            this.selectedUnselectedItems,
            true,
            this.isAllUnit
          );
        }
        const currentPage = filter.currentPage || this.currentPage;

        const perPage = ["dashboard", "report"].includes(this.componentKey)
          ? 50
          : filter.perPage || this.perPage;
        const key = filter.key || this.key;
        this.show = true;
        const me = this;
        this.isCallApi = true;
        this.filter = {
          field: "all",
          value: this.search_label,
          operator: "ilike"
        };
        if (!this.filter || !this.filter.value) {
          this.filter = {};
        }
        let requestData = {
          page: currentPage,
          page_size: parseInt(perPage) || 1,
          load_type: key,
          filters:
            this.filter && Object.keys(this.filter).length > 0
              ? [this.filter]
              : []
        };
        if (
          !!this.accountId &&
          ["dashboard", "report", "repeater"].includes(this.componentKey)
        ) {
          requestData.account_id = this.accountId;
        }

        if (this.reportType) {
          requestData["report_type"] = this.reportType;

          if (
            this.$route.name === "edit-report" ||
            this.$route.name === "view-report"
          ) {
            requestData["report_id"] = this.$route.params.id;
          }
          requestData["sensors"] =
            this.sensors && this.sensors.length ? this.sensors : [];
          // requestData["report_id"] = this.reportId;
        } else if (this.alertId) {
          requestData["alert_id"] = this.alertId;
        }
        if (this.notification && this.notification.id) {
          requestData.notification_id = this.notification.id;
        }
        let response = await new UnitService().unitLookup(requestData);

        if (response && response.data) {
          this.show = false;
          this.isCallApi = false;
          const me = this;
          if (response.data.units) {
            this.unitData = {
              items:
                (response.data.units &&
                  response.data.units.list.map((u) => {
                    const alreadyExists =
                      this.selectedUnselectedItems.selected.find(
                        (o) => o.id === u.id
                      );
                    const alreadyRemoved =
                      this.selectedUnselectedItems.unSelected.find(
                        (o) => o.id === u.id
                      );
                    if (
                      !!this.isAllUnit &&
                      ["dashboard", "report"].includes(this.componentKey)
                    ) {
                      this.selectedUnselectedItems.selected.push(u);
                      this.responseUnitData.selected.push(u);
                    }
                    u.selected =
                      (alreadyExists && alreadyExists.id) ||
                      ((!alreadyRemoved || !alreadyRemoved.id) && u.selected) ||
                      (!!this.isAllUnit &&
                        ["dashboard", "report"].includes(this.componentKey))
                        ? true
                        : false;
                    u.is_selected =
                      (!alreadyRemoved || !alreadyRemoved.id) && u.selected
                        ? true
                        : false;
                    u.variant = this.getRandomBgColor();
                    u.avtar_txt = this.userAvatarTxt(u.name);
                    return u;
                  })) ||
                [],
              total:
                (response.data.units.pagination &&
                  response.data.units.pagination.total_records) ||
                0,
              key: "unit"
            };
          }
          if (response.data.groups) {
            this.groupData = {
              items:
                (response.data.groups &&
                  response.data.groups.list.map((u) => {
                    const alreadyExists =
                      this.selectedUnselectedItems.selected.find(
                        (o) => o.id === u.id
                      );
                    const alreadyRemoved =
                      this.selectedUnselectedItems.unSelected.find(
                        (o) => o.id === u.id
                      );
                    u.selected =
                      (alreadyExists && alreadyExists.id) ||
                      ((!alreadyRemoved || !alreadyRemoved.id) && u.selected)
                        ? true
                        : false;
                    u.is_selected =
                      (!alreadyRemoved || !alreadyRemoved.id) && u.selected
                        ? true
                        : false;
                    u.variant = this.getRandomBgColor();

                    u.avtar_txt = this.userAvatarTxt(u.name);
                    return u;
                  })) ||
                [],
              total:
                (response.data.groups.pagination &&
                  response.data.groups.pagination.total_records) ||
                0,
              key: "group"
            };
          }
          if (
            (response.data.units &&
              response.data.units.list &&
              response.data.units.list.length) ||
            (response.data.groups &&
              response.data.groups.list &&
              response.data.groups.list.length)
          ) {
            this.isUnitsGroupsAvailable = false;
            EventBus.$emit("check-is-have-unit-or-group", false);
          } else {
            this.isUnitsGroupsAvailable = true;
            EventBus.$emit("check-is-have-unit-or-group", true);
          }
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.isCallApi = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.isCallApi = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    pagination(page) {
      if (page && page.key) {
        this.getGroupUnitLookUp({
          currentPage: page.currentPage,
          perPage: page.perPage,
          key: page.key.toUpperCase()
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.unit-icon-card {
  padding: 23px;
  background-color: var(--white);
  z-index: 9;
  // height: 50vh;
  overflow: auto;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  // margin-bottom: 1rem;
}
.input-prefix-img {
  border: 1px solid var(--gray4);
  height: 37.5px;
  width: 40px;
  border-radius: 7px 0px 0px 7px;
  text-align: center;
  img {
    height: 32px;
    margin: auto;
    padding: 3px;
  }
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 5px;
    }
  }
}
.unit-icon-modal {
  position: absolute;
  z-index: 9;
  padding: $px_10;
  min-width: $percent_100;
  // height: 60vh;
  padding: 5px 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: 0;
}
.unit-image {
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 8px;
      border-radius: initial;
    }
  }
}
.alert-action-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
}
.status-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
  text-align: right;
}
.group-width {
  text-align: right;
}
.st-170 {
  max-width: 170px;
  width: 170px;
  min-width: 170px;
  text-align: right !important;
}
.unit-search-comp {
  .unit-icon-card {
    padding-bottom: 0px;
  }
  .unit-icon-card {
    .b-avatar-img img {
      padding: 4px;
    }
  }
}
.unit-filter-report {
  .unit-search-comp {
    .unit-icon-card {
      min-height: calc(70vh - 120px);
    }
  }
}
</style>
<style lang="scss">
.dash-notification-scroll {
  margin-top: 10px !important;
  .pagination {
    margin-bottom: 8px !important;
  }
}
.dashboard-filter-scroll .dash-notification-scroll {
  .unit-icon-card {
    margin-left: 0rem;
    margin-right: 0rem;
    padding: 10px;
  }
}
.dash-notification-scroll.unit-selection-responsive .table-responsive {
  max-height: calc(75vh - 200px) !important;
}
</style>
